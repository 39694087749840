import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import ContactButton from "@/components/ContactButton.vue";

const defaultProps = {
  mail: "user@test.com",
};

const component = (props = defaultProps) => {
  return myRentalsShallowMount(ContactButton, {
    props,
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("ContactButton", () => {
  testMyRentalsShallowMount(ContactButton, {
    props: defaultProps,
  });

  describe("Computed", () => {
    describe("handleButtonClick", () => {
      it("Should open mail client", () => {
        // given
        Object.defineProperty(window, "location", { value: { href: null } });
        const button = component();
        // when
        button.vm.handleButtonClick();
        // then
        expect(location.href).toContain(`mailto:${defaultProps.mail}`);
      });
      it("Should open whatsapp if he has whatsappNumber client", () => {
        // given
        const whatsappProps = {
          mail: "user@test.com",
          whatsappNumber: "66666666",
        };
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappProps.whatsappNumber}`;
        spyOn(window, "open");
        const button = component(whatsappProps);
        // when
        button.vm.handleButtonClick();
        // then
        expect(window.open).toHaveBeenCalledWith(whatsappUrl);
      });
    });
  });
});
